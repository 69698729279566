import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action, toJS } from 'mobx';
import type { FormInstance } from 'rc-field-form';
import React from 'react';
import shortid from 'shortid';
import { api } from '../../../../utils';
import { scrollToAnchor, escape2Html } from '../../../../utils/tools';
import { getImageUrl, dealUnlegalImg, getImageInfo } from '../../../shelvesBase';
import { SizeColorStore } from '../../components';
import type { ICatAndDeliveryTemplates, IObj, labelAndValue, IShelfResult } from '../../interface';

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public saleInfoRef = React.createRef<FormInstance>();// 销售信息ref

  @observable public sizeColorStore = new SizeColorStore(this);

  @observable public loading = false;

  @observable public hasWrittenInfo: IObj;

  // @observable public pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？]/g;
  
  @observable public NAME_TYPE_MAP = {
    attach51: '吊牌价',
    attach52: '耐久性标签',
    attach55: '质检报告',
    attach56: '合格证',
  };

  @observable public qualificationPics = [
    {
      type: 'attach51', // 吊牌价
      name: '吊牌价',
      fileList: [],
      status: 'init', // 是否上传
    },
    {
      type: 'attach52', // 耐久性标签
      name: '耐久性标签',
      fileList: [],
      status: 'init',
    },
    {
      type: 'attach55', // 质检报告
      name: '质检报告',
      fileList: [],
      status: 'init',
    },
    {
      type: 'attach56', // 合格证
      name: '合格证',
      fileList: [],
      status: 'init',
    },
  ];

  @observable public provinceList: labelAndValue[];

  @observable public cityList: labelAndValue[];

  @observable public sizeMap: Array<{ templateId: string | number; templateName: string; }>;

  @observable public sizeChartTemplate = '';

  @observable public modelTryOnPic: IObj[] = []; // 模特试穿信息图

  @observable public provinceId = '';

  @observable public province = '';

  @observable public cityId = '';

  @observable public city = '';

  // @observable public cids = []; // 店铺中分类

  @observable public extractWay = ['2']; // 提取方式

  @observable public postFeeMode = '2'; // 买家或卖家支付运费

  @observable public sellingPoint = ''; // 商品卖点

  @observable public verticalImage = [
    {
      url: '',
      status: 'init',
    },
  ]; // 商品长图

  @observable public whiteBgImage = [
    {
      url: '',
      status: 'init',
    },
  ]; // 透明素材图

  @observable public invoice = true; // 发票

  @observable public deliveryTemplateLoading = false;// 运费模版loading

  @observable public warrantyService = ['2']; // 售后服务

  @observable public memberDiscount = 'false'; // 会员让利

  @observable public stockCountMode = 'true'; // 拍下减库存

  @observable public rebateRatio = '0.5'; // 返点比例

  @observable public listingTimeMode = '0'; // 上架时间

  @observable public sizeMeasureImage = '';

  @observable public sizeMeasureImageLists = [];

  @observable public visible = false;

  @observable public detailId = '';

  @observable public shopName = '';

  @observable public submitParams = {};

  @observable public freightTemplateList: IObj[]; // 运费模版

  @observable public deliveryTemplateId = undefined;
  
  @observable public feeTpl = 'postage';

  @observable public postFee = null;

  @observable public expressFee = null;

  @observable public emsFee = null;

  @action public releaseParams = async(type): Promise<any> => {
    const { getFieldsValue } = this.parent.tianmaoFormRef.current;
    const formData = { ...getFieldsValue() };
    const params: IObj = { gmsGoodsId: this.parent.gmsGoodsId };
    const baseinfoForm = this.parent.baseinfoStore.baseinfoFormRef.current;
    const baseinfoFormData = this.parent.baseinfoStore.getBaseInfo();
    const { goodsName, shortTitle, sellingPoint, cids, xinpin } = baseinfoFormData;

    // 资质图片处理（仅处理吊牌图和合格证）
    try {
      for (let i = 0; i < 2; i++) {
        const url = this.qualificationPics[i].fileList[0]?.url;
        if (url) {
          this.qualificationPics[i].fileList[0].url = await this.dealQualifyImg(url);
        }
      }
    } catch (e) {
      console.log('处理资质图片出错', e);
    }

    const gmsGoodsTmallExtendsRequest: IObj = {
      appDescription: '由PC端描述自动生成',
      attach51: this.qualificationPics[0].fileList[0]?.url || '',
      attach52: this.qualificationPics[1].fileList[0]?.url || '',
      attach55: this.qualificationPics[2].fileList[0]?.url || '',
      attach56: this.qualificationPics[3].fileList[0]?.url || '',
      sizeChartTemplate: this.sizeChartTemplate,
      provinceCode: `${this.provinceId}`,
      province: this.provinceList.find((item) => `${item.value}` === this.provinceId)?.label || '',
      city: this.cityList && this.cityList.find((item) => `${item.value}` === this.cityId)?.label || '',
      cityCode: `${this.cityId}`,
      deliveryWay: this.extractWay.includes('2') ? '2' : '',
      electronicTradeVoucher: '', // 暂时不能选
      invoice: true,
      modelTryOnPic: this.modelTryOnPic[0]?.url || '',
      modelTryOnSizeList: this.parent.tableStore.releaseTableList(),

      // verticalImage: this.verticalImage[0]?.url || '',
      // whiteBgImage: this.whiteBgImage[0]?.url || '',
      postFeeMode: this.postFeeMode,
      rebateRatio: formData.rebateRatio,
      returnCommitment: formData.warrantyService.includes('2') ? 'true' : 'false',
      stockCountMode: formData.stockCountMode,
      listingTimeMode: formData.listingTimeMode,
      memberDiscount: formData.memberDiscount,
      warrantyService: formData.warrantyService.includes('1') ? 'true' : 'false',
      sizeMeasureImage: this.sizeMeasureImage,

      // groupCode: this.parent.salePropsStore.groupCode,
      shortTitle,
      sellingPoint,
      cids,
      xinpin,
      
    };
    this.parent.listingTimeMode = formData.listingTimeMode;

    const saleValues = this.saleInfoRef.current?.getFieldsValue();
    const { goodsCode, salePrice, stockNum } = saleValues;

    params.goodsPutOnShelfTmallBaseRequest = {
      goodsName,
      goodsCode,
      salePrice,
      stockNum,
    };
    delete baseinfoFormData.shortTitle;
    delete baseinfoFormData.sellingPoint;
    delete baseinfoFormData.cids;
    delete baseinfoFormData.xinpin;
    delete baseinfoFormData.goodsName;
    if (!this.extractWay.includes('2')) {
      delete gmsGoodsTmallExtendsRequest.postFeeMode;
    }
    if (this.postFeeMode === '1') {
      if (this.feeTpl === 'postage') {
        gmsGoodsTmallExtendsRequest.freightByBuyer = {
          freightByBuyer: 'postage',
          postageId: this.deliveryTemplateId,
        };
      } else {
        gmsGoodsTmallExtendsRequest.freightByBuyer = {
          freightByBuyer: 'freight_details',
          postFee: this.postFee,
          expressFee: this.expressFee,
          emsFee: this.emsFee,
        };
      }
    }
    params.gmsGoodsTmallExtendsRequest = gmsGoodsTmallExtendsRequest;

    // 富文本
    let editorContent;
    try {
      editorContent = await this.parent.editorStore.relaseContent('save');
    } catch (e) {
      console.log(`富文本转换url failed ${e}`);
    }
    const verticalImage = this.verticalImage[0]?.url || '';
    const whiteBgImage = this.whiteBgImage[0]?.url || '';

    // 新增移动端详情图
    const wirelessDescPics = this.dealDetailPic();

    // 处理格式
    try {
      for (let i = 0; i < wirelessDescPics.length; i++) {
        const { Format } = await getImageInfo(wirelessDescPics[i]);
        wirelessDescPics[i] = dealUnlegalImg(wirelessDescPics[i], Format.value);
      }
    } catch (e) {
      console.log('处理详情图格式出错', e);
    }
   
    params.goodsPicVo = {
      mainPics: this.parent.mainImgsStore.getMainImgs(),
      htmlUrl: editorContent && editorContent.status === 'Successful' ? editorContent.data : '',
      productPics: [],
      fullScreenPics: verticalImage ? [verticalImage] : [],
      materialPics: whiteBgImage ? [whiteBgImage] : [],
      wirelessDescPics,
    };

    params.goodsProps = { ...baseinfoFormData };

    const checkedColorList = toJS(this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${ item.name}`,
      smallPicUrl: '',
    }));
    params.goodsPropPicVos = goodsPropPicVos;
    const goodsSkuVos = toJS(this.sizeColorStore.goodsSkuList);
    params.goodsSkuSetOnShelfRequestList = goodsSkuVos;

    if (this.parent.baseinfoStore.hasMaterialProps) {
      const materialProps = this.parent.materialStore.getMaterialLists(type);
      params.materialProps = materialProps;
    }
  
    return params;
  };

  // 处理详情图片尺寸
  private dealDetailPic = (): string[] => {
    // 详情根元素
    const detailRoot = document.querySelector('[data-contents=true]');
    
    // 获取详情的所有图片
    const imgs = detailRoot.querySelectorAll('img');
    const wirelessDescPics = [];
    for (let i = 0; i < imgs.length; i++) {
      const { width, height, src } = imgs[i];

      let resizeWidth: number;
      let resizeHeight: number;

      // 宽度750-1242
      resizeWidth = width < 750 ? 750 : width;
      resizeWidth = resizeWidth > 1242 ? 1242 : resizeWidth;

      // 高度<1546
      // eslint-disable-next-line prefer-const
      resizeHeight = height > 1546 ? 1546 : height;

      const dealedUrl = getImageUrl(src, resizeWidth, resizeHeight, width, height);
      
      wirelessDescPics.push(dealedUrl);
    }
    return wirelessDescPics;
  };

  // 保存
  @action public saveInfo = (): any => {
    this.dealDetailPic();
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('saleInfo');
      return;
    }

    this.saveInfoFn();
  };

  @action public saveInfoFn = async() => {
    // 保存的话不显示多少项没填
    this.parent.anchorStore.isValid = 'init';

    this.parent.loading = true;

    const params = await this.releaseParams('save');
    request<BaseData<any>>({
      url: api.saveGoodsPutOnShelf,
      method: 'post',
      data: { ...params },
    }).then((res) => {
      message.success('上架信息保存成功');
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 提交
  @action public submitInfo = async() => {
    // 销售信息校验
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('saleInfo');
      return;
    }

    this.parent.loading = true;

    try {
      this.parent.anchorStore.getIncomplete();
      const allTitleKeys = Object.keys(this.parent.anchorStore.allTitles);
      
      let saveEnabled = true;
      for (let i = 0; i < allTitleKeys.length; i++) {
        const eachItem = this.parent.anchorStore.allTitles[allTitleKeys[i]];
        if (eachItem.hasFilled < eachItem.requiredNum) {
          saveEnabled = false;
          scrollToAnchor(eachItem.id);
          break;
        }
      }
      
      if (!saveEnabled) {
        return;
      }

      const params = await this.releaseParams('submit');

      try {
        // 处理长图（大于500k压缩）
        params.goodsPicVo.fullScreenPics[0] = await this.dealLongImageSize(params.goodsPicVo.fullScreenPics[0]);
      } catch (e) {
        console.log('处理长图出错', e);
      }

      this.submitParams = params;
      this.submitInfoFn();
    } catch (e) {
      console.log(e);
    } finally {
      this.parent.loading = false;
    }
  };

  @action public submitInfoFn = () => {
    this.parent.loading = false;
    this.parent.submitResult = 'inProgress';
    this.parent.visibleSubmitResult = true;

    request<BaseData<IShelfResult>>({
      url: api.goodsPutonShelf,
      method: 'post',
      timeout: 60000,
      data: { ...this.submitParams },
    }).then((res) => {
      this.submitParams = {};
      this.parent.submitResult = 'Successful';
      const { platformId, shopName } = res.data;
      this.parent.detailId = platformId;
      this.parent.shopName = shopName;
    })
      .catch((e) => {
        message.destroy();
        this.parent.submitResult = 'Failed';
        this.parent.errorMessage = escape2Html(e.data?.info || '');
      });
  };

  @action public getId = () => {
    const id = shortid.generate();
    if (shortid.isValid(id)) {
      return id;
    }
    this.getId();
  };

  @action public changeSize = (e) => {
    this.sizeChartTemplate = e.target.value;
  };

  @action public handleChangeSelect = (value, type) => {
    this[type] = value;
    if (type === 'provinceId') {
      this.provinceId = `${value}`;
      this.province = this.provinceList.find((item) => item.value === value)?.label || '';
      this.parent.getCityList(value);
    }
    if (type === 'cityId') {
      this.cityId = `${value}`;
      this.city = this.cityList.find((item) => item.value === value)?.label || '';
    }
  };

  @action public changeTiqu = (checkedValues) => {
    this.extractWay = checkedValues;
    if (checkedValues.includes('2')) {
      this.postFeeMode = '2';
    }
  };

  @action public changeFreit = (e) => {
    this.postFeeMode = e.target.value;
  };

  @action public changeGoodsSellPoint = (e) => {
    this.sellingPoint = e.target.value;
  };

  // 回写资源图片
  @action public writeBackGoodsResourcePicVoList = (goodsResourcePicVoList) => {
    const qualificationPics = this.qualificationPics;
    for (let i = 0; i < qualificationPics.length; i++) {
      qualificationPics[i].fileList = [
        {
          url: goodsResourcePicVoList[qualificationPics[i].type],
          uid: this.getId(),
        },
      ];
    }
    this.qualificationPics = qualificationPics;
  };

  // 回写地址相关信息
  @action public writeBackAddress = (provinceCode = '', cityCode = '', province = '', city = '') => {
    if (provinceCode) {
      this.parent.getCityList(provinceCode).then((res) => {
        const data = res || [];
        this.city = data.find((item) => String(item.city_id) === cityCode)?.city_name || '';
        this.provinceId = provinceCode;
        this.cityId = cityCode;
        this.province = this.provinceList.find((item) => String(item.value) === provinceCode)?.label || '';
      });
    }
  };

  @action public writeBackOtherInfo = async(allInfo) => {
    const {
      cids = '', // 店铺中分类
      electronicTradeVoucher = '0', // 电子交易凭证
      deliveryWay, // 暂时只有邮寄 2
      invoice, // 是否提供发票 1-是, 0-否
      listingTimeMode, // 上架时间类型 0-立刻上架, 1-定时上架, 2-放入仓库
      // verticalImage, // 长图
      // whiteBgImage, // 透明素材图
      memberDiscount = 'false', // 会员让利： false-不参与会员让利 true-参与会员让利
      postFeeMode, // 运费方式：卖家承担运费(2)，买家承担运费(1)
      rebateRatio = '0.5', // 返点比例
      returnCommitment, // 是否提供退换货承诺  true-是, false-否
      sellingPoint, // 商品卖点
      stockCountMode, // 库存计数方式：是否拍下减库存
      warrantyService, // 是否提供保修服务  true-是, false-否
      sizeChartTemplate,
      groupCode,
      sizeMeasureImage,
      xinpin,
      shortTitle,
      freightByBuyer,
    } = allInfo.gmsGoodsTmallExtendsVo;

    // this.parent.salePropsStore.groupCode = groupCode;
    this.sizeMeasureImageLists = allInfo.sizeMeasureImage?.checkOptions || [];
    this.sizeMeasureImage = sizeMeasureImage || (this.sizeMeasureImageLists.length > 0 ? this.sizeMeasureImageLists[0].key : '');
    const goodsName = allInfo?.goodsPutOnShelfTmallBaseVo?.goodsName || '';

    // 回写基本信息
    this.parent.baseinfoStore.writeBackBaseinfo({
      goodsName,
      shortTitle: shortTitle || goodsName.slice(0, 10),
      sellingPoint,
      cids: cids ? cids.split(',') : [],
      xinpin: xinpin || 'false',
    });

    if (postFeeMode) {
      this.extractWay = ['2'];
    }
    let arr = ['2'];
    if (returnCommitment === 'false') {
      arr = [];
    }
    if (warrantyService === 'true') {
      arr.push('1');
    } else {
      arr = arr.filter((item) => item !== '1');
    }
    if (freightByBuyer) {
      this.feeTpl = freightByBuyer.freightByBuyer;
      this.deliveryTemplateId = freightByBuyer.postageId || undefined;
      this.postFee = freightByBuyer.postFee;
      this.expressFee = freightByBuyer.expressFee;
      this.emsFee = freightByBuyer.emsFee;
    }
    const { fullScreenPics, materialPics } = allInfo.goodsPicVo;
    let verticalImage = fullScreenPics[0] || '';
    let whiteBgImage = materialPics[0] || '';

    try {
      // 处理商品长图
      if (verticalImage) {
        verticalImage = await this.parent.dealLongImg(verticalImage);
      }
    } catch (e) {
      console.log('加载商品长图出错', e);
    }
    
    try {
      // 处理透明图
      if (whiteBgImage) {
        whiteBgImage = await this.parent.dealTransImg(whiteBgImage);
      }
    } catch (e) {
      console.log('加载透明图出错', e);
    }

    // 回写商品长图/透明图
    Object.assign(this, {
      // cids: cids ? cids.split(',') : [],

      // deliveryWay,
      verticalImage: [
        {
          url: verticalImage,
          uid: this.getId(),
          status: 'done',
        },
      ],
      whiteBgImage: [
        {
          url: whiteBgImage,
          uid: this.getId(),
          status: 'done',
        },
      ],

      warrantyService: arr,
      invoice: invoice || true,
      listingTimeMode: listingTimeMode || '2',
      memberDiscount: memberDiscount || 'false',
      postFeeMode: postFeeMode || '2',
      rebateRatio: rebateRatio || '0.5',
      stockCountMode: stockCountMode || 'true',
      sizeChartTemplate: sizeChartTemplate && `${sizeChartTemplate}`,
    });

    this.parent.tianmaoFormRef.current.setFieldsValue({
      warrantyService: arr,
      invoice: invoice || true,
      listingTimeMode: listingTimeMode || '2',
      memberDiscount: memberDiscount || 'false',
      rebateRatio: rebateRatio || '0.5',
      stockCountMode: stockCountMode || 'true',
    });
  };

  @action public onCkeckDiagram = (key) => {
    this.sizeMeasureImage = key;
  };

  @action public closeModal = () => {
    this.visible = false;
  };

  // 刷新运费模版
  @action public flushDeliveryTemplates = async() => {
    this.deliveryTemplateLoading = true;
    try {
      await this.parent.getTaobaDeliveryTemplates();
    } catch (e) {
      this.deliveryTemplateLoading = false;
    }
    
    // const req = await request<BaseData<ICatAndDeliveryTemplates>>({
    //   url: api.getCatAndDeliveryTemplates,
    //   method: 'post',
    //   data: { shopId: this.parent.shopId },
    // });
    // this.deliveryTemplateId = undefined;
    // this.freightTemplateList = this.parent.taobaoStore.disposeData(req?.data?.deliveryTemplateMap || []);
  };

  @action public changeFreight = (e, type) => {
    this[type] = e;
  };

  @action public changefreightTpl = (value) => {
    this.deliveryTemplateId = value;
  };

  @action public changeFeeTpl = (e) => {
    this.feeTpl = e.target.value;
  };

  // 处理天猫长图（大于500K做压缩）
  public dealLongImageSize = async(url) => {
    const res = await request<Blob>({
      url,
      withCredentials: false,
      responseType: 'blob',
    });
    const { size, type } = res;
    const newUrl = size > 512000 ? `${url}${!url.includes('x-oss-process=image') ? 'x-oss-process=image/' : ''}${ !type.includes('jpg') ? '/format,jpg' : ''}/quality,q_80` : url;
    return newUrl;
  };

  // 处理资质图片
  public dealQualifyImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);

    // 460*460
    let newUrl = getImageUrl(url, 460, 460, ImageWidth, ImageHeight);

    // 处理格式
    newUrl = dealUnlegalImg(newUrl, Format.value);

    return newUrl;
  };
}

export default Store;
